exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-florystykaslubna-js": () => import("./../../../src/pages/florystykaslubna.js" /* webpackChunkName: "component---src-pages-florystykaslubna-js" */),
  "component---src-pages-formularz-js": () => import("./../../../src/pages/formularz.js" /* webpackChunkName: "component---src-pages-formularz-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-poznajnas-js": () => import("./../../../src/pages/poznajnas.js" /* webpackChunkName: "component---src-pages-poznajnas-js" */)
}

